import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import RouterApp from './RouterApp'
import './styles/main.scss'
import 'antd/dist/antd.css';
class App extends Component {
  state = {
    logged: false
  }

  componentDidMount(){
    if(localStorage.getItem('token')){
      fetch(`https://tools.tiendavenus.com.mx/auth/?token=${localStorage.getItem('token')}`,  { cache: 'no-cache' })
    .then(respuesta => respuesta.json())
        .then(res => {
          res.access ? 
          this.logging(res) :
          localStorage.removeItem('token')
        });
    }
  }

  onFinish = (values) => {
    fetch(`https://tools.tiendavenus.com.mx/auth/?user=${values.username}&password=${values.password}`)
    .then(respuesta => respuesta.json())
        .then(res => {
          res.access ? 
          this.logging(res) :
          notification.error({
            message: "Error al iniciar sesión",
            description: "Usuario o contraseña incorrecto",
            placement: "bottomRight"
          });
        });
  };

  logging = (res) =>{
    console.log(res)
    this.setState({
      logged: true
    })
    localStorage.setItem('token', res.token)
  }

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  render() {
    return (this.state.logged ? 
    <RouterApp />
    :
      <div id="todo">
        <div className="login">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            autoComplete="off"
            className="login-form"
          >
            <Form.Item
              label="Usuario"
              name="username"
              rules={[{ required: true, message: 'Por favor ingrese su usuario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Contraseña"
              name="password"
              rules={[{ required: true, message: 'Por favor ingrese su contraseña' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Ingresar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default App;