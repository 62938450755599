import React, { Component } from 'react';
import { Table, Tag, Space, Button, Radio, Popconfirm, notification, Layout, Form, Input, Select } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;
const { Header, Content, Sider } = Layout;
const { TextArea } = Input;

class Lotes extends Component {
    state = {
        data: [],
        tempData: {},
        edit: false,
        add: false,
        value: '',
        verify: false,
        message: ''
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetch(`https://tools.tiendavenus.com.mx/existencias/?token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res =>
                this.setState({
                    data: res,
                    tempData: {},
                    edit: false,
                    add: false,
                    value: '',
                    verify: false,
                    message: ''
                })
            );
    }

    anularDocumento(id) {
        fetch(`https://tools.tiendavenus.com.mx/anular/?id=${id}&token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res => {
                console.log(res)
                if (res.status) {
                    notification.success({
                        message: "Documento anulado exitosamente",
                        description: res.message,
                        placement: "bottomRight"
                    })
                    this.getData()
                } else {
                    notification.error({
                        message: "Error al anular documento",
                        description: res.message,
                        placement: "bottomRight"
                    })
                }

            });
    }
    verifyValue = () => {
        if (!this.state.verify) {
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/importar/index.php',
                headers: { 'content-type': 'application/json' },
                data: {
                    token: localStorage.getItem('token'),
                    value: this.state.value,
                    verify: 1
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status) {
                    notification.success({
                        message: "Valores verificados con exito",
                        description: res.data.message,
                        placement: "bottomRight"
                    })
                } else {
                    notification.error({
                        message: "Hubo errores al verificar los valores",
                        description: res.data.message,
                        placement: "bottomRight"
                    })
                }
                this.setState({
                    verify: res.data.status,
                    message: res.data.message
                })
            });
        }
    }

    onFinish = (values) => {
        axios({
            method: 'post',
            url: 'https://tools.tiendavenus.com.mx/importar/index.php',
            headers: { 'content-type': 'application/json' },
            data: {
                token: localStorage.getItem('token'),
                value: values.value,
                verify: 0
            }
        }).then(res => {
            console.log(res.data)
            if (res.data.status) {
                notification.success({
                    message: "Lotes creados con exito",
                    description: res.data.message,
                    placement: "bottomRight"
                })
                this.getData()
            } else {
                notification.error({
                    message: "Hubo error al crear el lote",
                    description: res.data.message,
                    placement: "bottomRight"
                })
            }

        });

    };

    render() {
        return (
            <React.Fragment>
                <Content>
                    <div className="home">
                        <div className="header">
                            <h1>Lotes</h1>
                            <Button type="primary" shape="round" icon={<PlusOutlined />} size={'large'} onClick={() => this.props.history.push(`/lotes/crear`)}>
                                Ingresar Lote
                            </Button>
                        </div>
                        <div className="content">

                            <Table dataSource={this.state.data} columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                },
                                {
                                    title: 'Producto',
                                    dataIndex: 'producto',
                                    key: 'nombre',
                                },
                                {
                                    title: 'Lote',
                                    dataIndex: 'nombreLote',
                                    key: 'sku',
                                },
                                {
                                    title: 'Cantidad actual',
                                    dataIndex: 'cantidad',
                                    key: 'precio1',
                                },
                                {
                                    title: 'Cantidad inicial',
                                    dataIndex: 'cantidadInicial',
                                    key: 'precio2',
                                },
                            ]} />

                        </div>
                    </div>
                </Content>
                {(this.state.edit || this.state.add) &&
                    <Sider>
                        <div className="aside-bar">
                            <div className="header">
                                <h4>{this.state.edit ? 'EDITAR' : 'AGREGAR'} LOTE</h4>
                            </div>
                            <Form
                                initialValues={this.state.tempData}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Ingrese Lote en el formato indicado (SKU,CANTIDAD,NOMBRELOTE)"
                                    name="value"
                                    rules={[{ required: true }]}
                                    tooltip={{ title: 'Productos separados por lineas', icon: <InfoCircleOutlined /> }}
                                >
                                    <TextArea rows={4} onChange={(e) => this.setState({ value: e.target.value, verify: false })} placeholder="SKU,CANTIDAD,NOMBRELOTE" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="secondary" onClick={() => this.verifyValue()}>
                                        Verificar
                                    </Button>
                                    <Button type="primary" htmlType="submit" disabled={!this.state.verify}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Form>
                            <p className="mensaje">
                                {this.state.message}
                            </p>
                        </div>
                    </Sider>}
            </React.Fragment>
        );
    }
}

export default Lotes;