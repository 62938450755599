import React, { Component } from 'react';
import { Menu } from 'antd';
import { HomeOutlined, ShoppingCartOutlined, UsergroupAddOutlined, ImportOutlined } from '@ant-design/icons';
import {
  NavLink,
  Link, useLocation
} from "react-router-dom";

const { SubMenu } = Menu;

class Header extends Component {
  state = {
    current: '/',
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };
  render() {
    const { current } = this.state;
    return (
      <Menu onClick={this.handleClick} selectedKeys={[current]} theme="dark" mode="horizontal">
        <Menu.Item key="/" icon={<HomeOutlined />}>
          <NavLink to="/">Movimientos</NavLink>
        </Menu.Item>
        <Menu.Item key="/lotes" icon={<ImportOutlined />}>
          <NavLink to="/lotes">Lotes</NavLink>
        </Menu.Item>
        <Menu.Item key="/productos" icon={<ShoppingCartOutlined />}>
          <NavLink to="/productos">Productos</NavLink>
        </Menu.Item>
        <Menu.Item key="/clientes" icon={<UsergroupAddOutlined />}>
          <NavLink to="/clientes">Clientes</NavLink>
        </Menu.Item>
      </Menu>
    );
  }
}

export default Header;