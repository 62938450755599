import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header2 from './Header'
import Home from './Home'
import Productos from './Productos';
import { Layout} from 'antd';
import Clientes from './Clientes';
import Lotes from './Lotes';
import Create from './Create';
import CreateEntrada from './CreateEntrada';

const { Header, Content, Footer } = Layout;

class RouterApp extends Component {
  render() {
    return (
      <Router>

        <Layout>
          <Header><Header2
          /></Header>
          <Layout>
            <Switch>
              <Route path="/crear" component={Create} />
              <Route path="/lotes/crear" component={CreateEntrada} />
              <Route path="/lotes" component={Lotes} />
              <Route path="/clientes" component={Clientes} />
              <Route path="/productos" component={Productos} />
              <Route path="/" component={Home} />
            </Switch>
          </Layout>
        </Layout>
      </Router>
    );
  }
}

export default RouterApp;