import React, { Component } from 'react';
import { Table, Tag, Space, Button, Radio, Popconfirm, Card, notification, Layout, Form, Input, Select, InputNumber, Switch } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;
const { Header, Content, Sider } = Layout;
const { TextArea } = Input;

class Create extends Component {
    state = {
        data: [],
        tempData: {},
        edit: false,
        add: false,
        value: '',
        verify: false,
        message: '',
        clientes: [],
        productos: [],
        cliente: 0,
        listaPrecio: 0,
        precio1: 0,
        precio2: 0,
        precio3: 0,
        lotes: [],
        productosComponentes: [],
        sending: false,
        fecha: ''
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetch(`https://tools.tiendavenus.com.mx/existencias/initialState.php?token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res => {
                console.log(res)
                this.setState({
                    productos: res.productos,
                    clientes: res.clientes,
                    tempData: {},
                    edit: false,
                    add: false,
                    value: '',
                    verify: false,
                    message: ''
                })
            }
            );
    }

    handleInputLote = (value, loteId, loteNombre, precio1, precio2, precio3, sku) => {
        var arr = this.state.lotes
        let lote = arr.filter(item => parseInt(item.id) === parseInt(loteId))
        let lotesFinales = []
        let precio1arr = 0
        let precio2arr = 0
        let precio3arr = 0
        if (value) {
            if (lote.length) {
                lotesFinales = arr.filter(item => parseInt(item.id) !== parseInt(loteId))
                precio1arr = this.state.precio1 - (lote[0].precio1 * lote[0].cantidad) + (precio1 * value)
                precio2arr = this.state.precio2 - (lote[0].precio2 * lote[0].cantidad) + (precio2 * value)
                precio3arr = this.state.precio3 - (lote[0].precio3 * lote[0].cantidad) + (precio3 * value)

                lotesFinales.push({
                    id: loteId,
                    name: loteNombre,
                    cantidad: value,
                    precio1,
                    precio2,
                    precio3,
                    sku,
                    skuPadre: ''
                })
            } else {
                lotesFinales = arr
                precio1arr = this.state.precio1 + (precio1 * value)
                precio2arr = this.state.precio2 + (precio2 * value)
                precio3arr = this.state.precio3 + (precio3 * value)

                lotesFinales.push({
                    id: loteId,
                    name: loteNombre,
                    cantidad: value,
                    precio1,
                    precio2,
                    precio3,
                    sku,
                    skuPadre: ''
                })
            }
        } else {
            if (lote.length) {
                lotesFinales = arr.filter(item => parseInt(item.id) !== parseInt(loteId))
                precio1arr = this.state.precio1 - (lote[0].precio1 * lote[0].cantidad)
                precio2arr = this.state.precio2 - (lote[0].precio2 * lote[0].cantidad)
                precio3arr = this.state.precio3 - (lote[0].precio3 * lote[0].cantidad)

            } else {
                lotesFinales = arr
                precio1arr = this.state.precio1
                precio2arr = this.state.precio2
                precio3arr = this.state.precio3
            }
        }
        this.setState({
            lotes: lotesFinales,
            precio1: precio1arr,
            precio2: precio2arr,
            precio3: precio3arr,
        })

    }

    verifyValue = () => {
        if (!this.state.verify) {
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/importar/index.php',
                headers: { 'content-type': 'application/json' },
                data: {
                    token: localStorage.getItem('token'),
                    value: this.state.value,
                    fecha: this.state.fecha,
                    verify: 1
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status) {
                    notification.success({
                        message: "Valores verificados con exito",
                        description: res.data.message,
                        placement: "bottomRight"
                    })
                } else {
                    notification.error({
                        message: "Hubo errores al verificar los valores",
                        description: res.data.message,
                        placement: "bottomRight"
                    })
                }
                this.setState({
                    verify: res.data.status,
                    message: res.data.message
                })
            });
        }
    }

    onFinish = (values) => {
        this.setState({
            sending: true
        })
        axios({
            method: 'post',
            url: 'https://tools.tiendavenus.com.mx/facturas/crear.php',
            headers: { 'content-type': 'application/json' },
            data: {
                token: localStorage.getItem('token'),
                cliente: this.state.cliente,
                listaPrecio: this.state.listaPrecio,
                lotes: this.state.lotes,
                notas: values.notas,
                fecha: this.state.fecha
            }
        }).then(res => {
            console.log(res.data)
            if (res.data.status) {
                notification.success({
                    message: "Movimiento creado con exito",
                    description: res.data.message,
                    placement: "bottomRight"
                })
                this.props.history.push(`/`)
            } else {
                notification.error({
                    message: "Hubo error al crear el movimiento",
                    description: res.data.message,
                    placement: "bottomRight"
                })
            }

        });

    };

    showPrice = () => {
        let price = this.state.listaPrecio

        switch (parseInt(price)) {
            case 1:
                return (this.state.precio1)
            case 2:
                return (this.state.precio2)
            case 3:
                return (this.state.precio3)
            default:
                return (0)
        }
    }

    changeProductoComponentes = (value, sku) => {
        var arr = this.state.productosComponentes.filter(item => item.sku !== sku)
        var lotes = this.state.lotes.filter(item => item.skuPadre !== sku)

        if (parseInt(value)) {
            arr.push({
                sku,
                value
            })
        }

        this.setState({
            productosComponentes: arr,
            lotes
        })

    }

    selectProductoComponentes = (e) => {

        console.log(e)

        const x = e.target.value.split(';')
        const sku = e.target.name
        const id = x[0]
        const skuPadre = x[1]
        const name = x[2]

        const cantidad = this.state.productosComponentes.filter(item => item.sku === skuPadre)[0].value
        var lotes = this.state.lotes.filter(item => item.sku !== sku)
        lotes.push({
            id,
            name,
            sku,
            cantidad,
            skuPadre
        })

        this.setState({
            lotes
        })

    }

    render() {
        return (this.state.productos.length ?
            <React.Fragment>
                <Form
                    initialValues={this.state.tempData}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Layout>
                        <Sider>
                            <Card title="Info" bordered={true} >

                                <Form.Item
                                    name="cliente"
                                    label="Seleccione un cliente"
                                    rules={[{ required: true }]}
                                >
                                    <Select placeholder="Seleccione un cliente..." showSearch filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    } onChange={(e) => {
                                        let arr = e.split(';')
                                        this.setState({
                                            listaPrecio: arr[1],
                                            cliente: arr[0]
                                        })
                                    }}>
                                        {this.state.clientes.map(item => (
                                            <Option value={item.id + ';' + item.listaPrecio}>{item.nombre}</Option>
                                        ))}

                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="fecha"
                                    label="Fecha de salida"
                                >
                                    <Input type='date' style={{ width: '100%', marginRight: '12px' }} required onChange={(e) => this.setState({ fecha: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    label="Notas"
                                    name="notas"
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Card>
                        </Sider>
                        <Content>
                            <div className="home">
                                <div className="header">
                                    <h1>Crear Salida</h1>

                                    <Form.Item>
                                        <Button type="primary" shape="round" size={'large'} htmlType="submit" disabled={this.state.sending}>
                                            Guardar
                                        </Button>

                                    </Form.Item>
                                </div>
                                <Card title="Productos" bordered={true} className="productos-card" >
                                    {this.state.productos.map(item => (
                                        <div className="producto-card">
                                            <div className="img-container">
                                                <img src={item.img_link} alt="" />
                                            </div>
                                            <div className="text-container">
                                                <p>{item.nombre}</p>
                                                <b>{item.sku}</b> <br />
                                                {item.componente ?
                                                    <Space>
                                                        <InputNumber
                                                            min={0}
                                                            style={{ width: 60 }}
                                                            onChange={(value) => this.changeProductoComponentes(value, item.sku)}
                                                        />
                                                    </Space> : ''
                                                }
                                            </div>
                                            {item.componente && this.state.productosComponentes.filter(itemlot2 => itemlot2.sku === item.sku).length ?
                                                item.componentes.map(lotcom => (
                                                    <div className="lotcom">
                                                        <h2>{lotcom.sku}</h2>
                                                        <div className="lote-container">
                                                            <h4>{lotcom.lotes.length === 0 ? 'Sin lotes con existencia' : 'Escoja un lote'}</h4>

                                                            <Radio.Group onChange={(value) => this.selectProductoComponentes(value)} name={lotcom.sku} >



                                                                {lotcom.lotes.length !== 0 && lotcom.lotes.filter(itemlot => (itemlot.cantidad >= (this.state.productosComponentes.filter(itemlot2 => itemlot2.sku === item.sku)[0].value))).map(lotcom_item => (
                                                                    <div className="group">
                                                                        <Radio value={lotcom_item.id + ';' + item.sku + ';' + lotcom_item.nombre} >{lotcom_item.nombre + ' (' + lotcom_item.cantidad + 'pz. / ' + lotcom_item.caducidad + ')'}</Radio>
                                                                    </div>

                                                                ))}
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                item.componente
                                                    ?
                                                    <h5>Ingrese cuantos productos desea dar salida</h5>

                                                    :
                                                    <div className="lote-container">
                                                        <h4>{item.lotes.length === 0 ? 'Sin lotes con existencia' : ''}</h4>
                                                        {item.lotes.length === 0 ? '' : item.lotes.map(lote => (
                                                            <div className="group" style={{ gap: '10px' }}>
                                                                <h5>{lote.nombre + ' / ' + lote.caducidad}<small style={{ fontSize: '12px' }}>{' (' + lote.cantidad + 'pz.)'}</small></h5>
                                                                <Space>
                                                                    <InputNumber
                                                                        min={0}
                                                                        max={lote.cantidad}
                                                                        style={{ width: '100%' }}
                                                                        name={lote.id + ';' + lote.nombre}
                                                                        //value={value} 
                                                                        onChange={(value) => this.handleInputLote(value, lote.id, lote.nombre, item.precio1, item.precio2, item.precio3, item.sku)}
                                                                    />
                                                                </Space>
                                                            </div>
                                                        ))}

                                                    </div>
                                            }

                                        </div>
                                    ))}
                                </Card>
                            </div>
                        </Content>
                    </Layout>
                </Form>
            </React.Fragment>
            : '');
    }
}

export default Create;