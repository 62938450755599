import React, { Component } from 'react';
import { Table, Tag, Space, Button, Radio, Popconfirm, Card, notification, Layout, Form, Input, Select, InputNumber } from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;
const { Header, Content, Sider } = Layout;
const { TextArea } = Input;

class CreateEntrada extends Component {
    state = {
        data: [],
        tempData: {},
        edit: false,
        add: false,
        value: '',
        verify: false,
        message: '',
        clientes: [],
        productos: [],
        cliente: 0,
        listaPrecio: 0,
        precio1: 0,
        precio2: 0,
        precio3: 0,
        lotes: [],
        nombreLote: '',
        fecha: '',
        notas: '',
        sending: false
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetch(`https://tools.tiendavenus.com.mx/existencias/initialState.php?token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res => {
                console.log(res)
                this.setState({
                    productos: res.productos,
                    clientes: res.clientes,
                    tempData: {},
                    edit: false,
                    add: false,
                    value: '',
                    verify: false,
                    message: ''
                })
            }
            );
    }

    handleInputLote = (value, loteId, loteNombre, sku) => {

        console.log(document.getElementsByName('caducidad-' + loteId)[0].value)

        var arr = this.state.lotes
        let lote = arr.filter(item => parseInt(item.id) === parseInt(loteId))
        let lotesFinales = []
        if (value) {
            if (lote.length) {
                lotesFinales = arr.filter(item => parseInt(item.id) !== parseInt(loteId))

                lotesFinales.push({
                    id: loteId,
                    name: loteNombre,
                    cantidad: value,
                    sku,
                    caducidad: document.getElementsByName('caducidad-' + loteId)[0].value
                })
            } else {
                lotesFinales = arr

                lotesFinales.push({
                    id: loteId,
                    name: loteNombre,
                    cantidad: value,
                    sku,
                    caducidad: document.getElementsByName('caducidad-' + loteId)[0].value
                })
            }
        } else {
            if (lote.length) {
                lotesFinales = arr.filter(item => parseInt(item.id) !== parseInt(loteId))

            } else {
                lotesFinales = arr
            }
        }
        this.setState({
            lotes: lotesFinales,
        })

    }

    verifyValue = () => {
        if (!this.state.verify) {
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/importar/index.php',
                headers: { 'content-type': 'application/json' },
                data: {
                    token: localStorage.getItem('token'),
                    value: this.state.value,
                    fecha: this.state.fecha,
                    notas: this.state.notas,
                    verify: 1
                }
            }).then(res => {
                console.log(res.data)
                if (res.data.status) {
                    notification.success({
                        message: "Valores verificados con exito",
                        description: res.data.message,
                        placement: "bottomRight"
                    })
                } else {
                    notification.error({
                        message: "Hubo errores al verificar los valores",
                        description: res.data.message,
                        placement: "bottomRight"
                    })
                }
                this.setState({
                    verify: res.data.status,
                    message: res.data.message
                })
            });
        }
    }

    onFinish = () => {
        var values = ''
        this.state.lotes.map(item => {
            values = values + item.sku + ',' + item.cantidad + ',' + this.state.nombreLote + ',' + item.caducidad + ';'
        })
        this.setState({
            sending: true
        })

        axios({
            method: 'post',
            url: 'https://tools.tiendavenus.com.mx/importar/index.php',
            headers: { 'content-type': 'application/json' },
            data: {
                token: localStorage.getItem('token'),
                value: values,
                fecha: this.state.fecha,
                notas: this.state.notas,
                verify: 0
            }
        }).then(res => {
            console.log(res.data)
            if (res.data.status) {
                notification.success({
                    message: "Lotes creados con exito",
                    description: res.data.message,
                    placement: "bottomRight"
                })
                this.props.history.push(`/lotes`)
            } else {
                notification.error({
                    message: "Hubo error al crear el lote",
                    description: res.data.message,
                    placement: "bottomRight"
                })
            }

        });

    };


    render() {
        return (this.state.productos.length ?
            <React.Fragment>
                <Form
                    initialValues={this.state.tempData}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Layout>
                        <Content>
                            <div className="home">
                                <div className="header">
                                    <h1>Crear Entrada</h1>

                                    <Form.Item>
                                        <Input type='date' style={{ width: 250, marginRight: '12px' }} required onChange={(e) => this.setState({ fecha: e.target.value })} />
                                        <Input style={{ width: 250, marginRight: '12px' }} placeholder="Nombre del lote" required onChange={(e) => this.setState({ nombreLote: e.target.value })} />
                                        <Input placeholder="Notas (opcional)" style={{ width: 300 }} required onChange={(e) => this.setState({ notas: e.target.value })} />
                                        <Button style={{ marginLeft: '12px' }} type="primary" htmlType="submit" disabled={this.state.sending}>
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </div>
                                <Card title="Productos" bordered={true} className="productos-card" >
                                    {this.state.productos.map(item => (
                                        <div className="producto-card">
                                            <div className="img-container">
                                                <img src={item.img_link} alt="" />
                                            </div>
                                            <div className="text-container">
                                                <p>{item.nombre}</p>
                                                <b>{item.sku} - </b>
                                                <p>Caducidad: </p>
                                                <Input name={`caducidad-${item.id}`} type='date' style={{ width: '150px' }} />
                                                <p>Cantidad: </p>
                                                <Space>
                                                    <InputNumber
                                                        style={{ width: '150px' }}
                                                        name={''}
                                                        min={0}
                                                        //value={value} 
                                                        onChange={(value) => this.handleInputLote(value, item.id, item.nombre, item.sku)}
                                                    />
                                                </Space>
                                            </div>
                                        </div>
                                    ))}
                                </Card>
                            </div>
                        </Content>
                    </Layout>
                </Form>
            </React.Fragment>
            : '');
    }
}

export default CreateEntrada;