import React, { Component } from 'react';
import axios from 'axios';
import { notification } from 'antd';

class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
    this.imgRef = React.createRef();
  }

  state = {
    imageSrc: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: {},
    aspect: this.props.aspect,
    idDisplay: this.props.idDisplay,
    //1:user  2:children  3: caretaker
    id: this.props.id,
    // rect or round
    cropShape: this.props.shape
  }

  handleClick = () => {
    document.querySelector('#file-input-image2').click()
  }

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  handleFileInputChange = e => {
    console.log(e.target.files[0]);
    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          imageSrc: result,
          file
        });
        this.saveImage(result)
      })
      .catch(err => {
        console.log(err);
      });

  };

  saveImage = (image) => {
    axios({
      method: 'post',
      url: `https://tools.tiendavenus.com.mx/images/index.php`,
      headers: { 'content-type': 'application/json' },
      data: {
        image
      }
    }).then(resultado => this.sendImage(resultado.data));
  }

  sendImage = (resultado) => {
    if (resultado.id === 1) {
      notification.success({
        message: resultado.message,
        placement: "bottomRight"
      });
      this.props.sendImage(resultado.data)
    } else {
      notification.error({
        message: resultado.message,
        placement: "bottomRight"
      });
    }
  }

  render() {
    return (
        <div className={"crop-container"}>
            <div className="beforeCropping">
                <label htmlFor="">Imagen</label>
              <input className="file-input-hidden" type="file" accept="image/*" name="" id="file-input-image2" onChange={this.handleFileInputChange} />
              
            </div>

        
            <br />
        </div>
    );
  }
}

export default UploadImage;