import React, { Component } from 'react';
import { Table, Tag, Space, Button, Radio, Popconfirm, notification, Layout, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
const { Option } = Select;
const { Header, Content, Sider } = Layout;

class Clientes extends Component {
    state = {
        data: [],
        tempData: {},
        edit: false,
        add: false
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetch(`https://tools.tiendavenus.com.mx/clientes/?token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res =>
                this.setState({
                    data: res,
                    add: false,
                    edit: false
                })
            );
    }


    onFinish = (values) => {
        if(this.state.add){
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/clientes/add/index.php',
                headers: { 'content-type': 'application/json' },
                data: values
            }).then(res => {
                    console.log(res)
                    if(res.status){
                        notification.success({
                            message: "Cliente creado con exito",
                            description: res.message,
                            placement: "bottomRight"
                        })
                        this.getData()
                    }else{
                        notification.error({
                            message: "Error al crear cliente",
                            description: res.message,
                            placement: "bottomRight"
                        })
                    }
                });
        }else{
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/clientes/update/index.php',
                headers: { 'content-type': 'application/json' },
                data: {
                    ...values,
                    id: this.state.tempData.id
                }
            }).then(res => {
                    console.log(res)
                    if(res.status){
                        notification.success({
                            message: "Producto actualizado con exito",
                            description: res.message,
                            placement: "bottomRight"
                        })
                        this.getData()
                    }else{
                        notification.error({
                            message: "Error al actualizar producto",
                            description: res.message,
                            placement: "bottomRight"
                        })
                    }
                });
        }
        
      };

    render() {
        return (
            <React.Fragment>
                <Content>
                    <div className="home">
                        <div className="header">
                            <h1>Clientes</h1>
                            <Button type="primary" shape="round" icon={<PlusOutlined />} size={'large'} onClick={()=>this.setState({tempData: {}, add: true})}>
                                Crear nuevo
                            </Button>
                        </div>
                        <div className="content">

                            <Table dataSource={this.state.data} columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    key: 'nombre',
                                },
                                {
                                    title: 'Direccion',
                                    dataIndex: 'direccion1',
                                    key: 'direccion1',
                                },
                                {
                                    title: 'Telefono',
                                    dataIndex: 'telefono',
                                    key: 'telefono',
                                },
                                {
                                    title: 'Email',
                                    dataIndex: 'email',
                                    key: 'email',
                                },
                                {
                                    title: '',
                                    key: 'action',
                                    render: (text, record) => (
                                        <Space size="middle">

                                            <a onClick={()=>this.setState({tempData: record, edit: true})}>Editar</a>
                                            {/* {!record.anulado &&
                      <Popconfirm title="¿Estas segur@？" onConfirm={()=>this.anularDocumento(record.id)} okText="Si" cancelText="No">
                        <a>Eliminar</a>
                      </Popconfirm>
                    } */}

                                        </Space>
                                    ),
                                },
                            ]} />

                        </div>
                    </div>
                </Content>
                {(this.state.edit || this.state.add) && 
                <Sider>
                    <div className="aside-bar">
                        <div className="header">
                            <h4>{this.state.edit ? 'EDITAR' : 'AGREGAR'} CLIENTE</h4>
                        </div>
                        <Form
                            initialValues={this.state.tempData}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Dirección 1"
                                name="direccion1"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Dirección 2"
                                name="direccion2"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Dirección 3"
                                name="direccion3"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Telefono"
                                name="telefono"
                                rules={[{ required: true}]}
                            >
                                
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="email"
                                name="email"
                                rules={[{ required: true}]}
                            >
                                
                                <Input />
                            </Form.Item>
                            <Form.Item
        name="listaPrecio"
        label="Lista de precio"
        rules={[{ required: true }]}
      >
        <Select placeholder="Seleccione una lista de precio">
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
        </Select>
      </Form.Item>
                            <Form.Item>
                            <Button type="secondary" onClick={()=>this.setState({add: false, edit: false})}>
                                    Cancelar
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Sider>}
            </React.Fragment>
        );
    }
}

export default Clientes;