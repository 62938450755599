import React, { Component } from 'react';
import { Table, Tag, Space, Button, Radio, Popconfirm, Select, notification, Layout, Form, Input} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import UploadImage from './UploadImage';
const { Option } = Select;
const { Header, Content, Sider } = Layout;

class Productos extends Component {
    state = {
        data: [],
        tempData: {},
        edit: false,
        add: false
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        fetch(`https://tools.tiendavenus.com.mx/productos/?token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res =>
                this.setState({
                    data: res,
                    add: false,
                    edit: false
                })
            );
    }

    anularDocumento(id) {
        fetch(`https://tools.tiendavenus.com.mx/anular/?id=${id}&token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
            .then(respuesta => respuesta.json())
            .then(res => {
                console.log(res)
                if (res.status) {
                    notification.success({
                        message: "Documento anulado exitosamente",
                        description: res.message,
                        placement: "bottomRight"
                    })
                    this.getData()
                } else {
                    notification.error({
                        message: "Error al anular documento",
                        description: res.message,
                        placement: "bottomRight"
                    })
                }

            });
    }

    onFinish = (values) => {
        if(this.state.add){
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/productos/add/index.php',
                headers: { 'content-type': 'application/json' },
                data: {
                    ...values,
                    img_link: this.state.tempData.img_link
                }
            }).then(res => {
                    console.log(res)
                    if(res.status){
                        notification.success({
                            message: "Producto creado con exito",
                            description: res.message,
                            placement: "bottomRight"
                        })
                        this.getData()
                    }else{
                        notification.error({
                            message: "Error al crear producto",
                            description: res.message,
                            placement: "bottomRight"
                        })
                    }
                });
        }else{
            axios({
                method: 'post',
                url: 'https://tools.tiendavenus.com.mx/productos/update/index.php',
                headers: { 'content-type': 'application/json' },
                data: {
                    ...values,
                    id: this.state.tempData.id,
                    img_link: this.state.tempData.img_link
                }
            }).then(res => {
                    console.log(res)
                    if(res.status){
                        notification.success({
                            message: "Producto actualizado con exito",
                            description: res.message,
                            placement: "bottomRight"
                        })
                        this.getData()
                    }else{
                        notification.error({
                            message: "Error al actualizar producto",
                            description: res.message,
                            placement: "bottomRight"
                        })
                    }
                });
        }
        
      };

      handleChange = (value)=> {
        console.log(`selected ${value}`);
      }

    render() {
        return (
            <React.Fragment>
                <Content>
                    <div className="home">
                        <div className="header">
                            <h1>Productos</h1>
                            <Button type="primary" shape="round" icon={<PlusOutlined />} size={'large'} onClick={()=>this.setState({tempData: {}, add: true})}>
                                Crear Producto
                            </Button>
                        </div>
                        <div className="content">

                            <Table dataSource={this.state.data} columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                },
                                {
                                    title: 'Imagen',
                                    key: 'action',
                                    render: (text, record) => (
                                        <img src={record.img_link} height="40px"></img>
                                    ),
                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    key: 'nombre',
                                },
                                {
                                    title: 'SKU',
                                    dataIndex: 'sku',
                                    key: 'sku',
                                },
                                {
                                    title: 'Con componentes',
                                    dataIndex: 'hasComponents',
                                    key: 'hasComponents',
                                    render: (text, record) => (
                                        record.hasComponents ? 'Si' : 'No'
                                    ),
                                },
                                {
                                    title: 'Num. de componentes',
                                    dataIndex: 'numComponents',
                                    key: 'numComponents',
                                },
                                {
                                    title: '',
                                    key: 'action',
                                    render: (text, record) => (
                                        <Space size="middle">

                                            <a onClick={()=>this.setState({tempData: record, edit: true})}>Editar</a>
                                            {/* {!record.anulado &&
                      <Popconfirm title="¿Estas segur@？" onConfirm={()=>this.anularDocumento(record.id)} okText="Si" cancelText="No">
                        <a>Eliminar</a>
                      </Popconfirm>
                    } */}

                                        </Space>
                                    ),
                                },
                            ]} />

                        </div>
                    </div>
                </Content>
                {(this.state.edit || this.state.add) && 
                <Sider>
                    <div className="aside-bar">
                        <div className="header">
                            <h4>{this.state.edit ? 'EDITAR' : 'AGREGAR'} PRODUCTO</h4>
                        </div>
                        <Form
                            initialValues={this.state.tempData}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Sku"
                                name="sku"
                                rules={[{ required: true}]}
                            >
                                <Input />
                            </Form.Item>
                            <UploadImage
                                sendImage={(value)=>this.setState({
                                    tempData: {
                                        ...this.state.tempData,
                                        img_link: value
                                    }
                                })}
                             />
                            <Form.Item
                                label="Componentes: "
                                name="componentes"
                            >
                                <Select mode="tags" style={{ width: '100%' }} placeholder="Seleccione componentes..." onChange={this.handleChange}>
                                    {this.state.data.filter(item=> item.hasComponents === 0).map(item => (
                                        <Option key={item.sku}>{item.sku+' '+item.nombre}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                            <Button type="secondary" onClick={()=>this.setState({add: false, edit: false})}>
                                    Cancelar
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Sider>}
            </React.Fragment>
        );
    }
}

export default Productos;