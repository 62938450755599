import React, { Component } from 'react';
import { Table, Tag, Space, Button, Radio, Popconfirm, notification, Layout } from 'antd';
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
class Home extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    fetch(`https://tools.tiendavenus.com.mx/facturas/?token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
      .then(respuesta => respuesta.json())
      .then(res =>
        this.setState({
          data: res
        })
      );
  }

  anularDocumento(id) {
    fetch(`https://tools.tiendavenus.com.mx/anular/?id=${id}&token=${localStorage.getItem('token')}`, { cache: 'no-cache' })
      .then(respuesta => respuesta.json())
      .then(res => {
        console.log(res)
        if (res.status) {
          notification.success({
            message: "Documento anulado exitosamente",
            description: res.message,
            placement: "bottomRight"
          })
          this.getData()
        } else {
          notification.error({
            message: "Error al anular documento",
            description: res.message,
            placement: "bottomRight"
          })
        }

      });
  }
  render() {
    return (
      <Content>
        <div className="home">
          <div className="header">
            <h1>Movimientos</h1>
            <div className="btn-container">
              {/* <Button type="secondary" shape="round" icon={<ImportOutlined />} size={'large'}>
          Importar lote
        </Button> */}
              <Button type="primary" shape="round" icon={<PlusOutlined />} size={'large'} onClick={() => this.props.history.push(`/crear`)}>
                Crear Salida
              </Button>
            </div>

          </div>


          <Table dataSource={this.state.data} columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Cliente',
              dataIndex: 'cliente',
              key: 'cliente',
            },
            {
              title: 'Fecha',
              dataIndex: 'fecha',
              key: 'fecha',
            },
            {
              title: 'Hora',
              dataIndex: 'hora',
              key: 'hora',
            },
            {
              title: 'Estado',
              key: 'action',
              render: (text, record) => (
                record.anulado ?
                  'Anulado'
                  :
                  'Procesado'
              ),
            },
            {
              title: 'Tipo',
              key: 'action',
              render: (text, record) => (
                record.salidas ?
                  'Salida'
                  :
                  'Entrada'
              ),
            },
            {
              title: '',
              key: 'action',
              render: (text, record) => (
                <Space size="middle">

                  <a href={`https://tools.tiendavenus.com.mx/facturas/factura.php?id=${record.id}&token=${localStorage.getItem('token')}&key=${Math.random()}`} target="_blank">Ver</a>
                  {(!record.anulado && record.salidas) ?
                    <Popconfirm title="¿Estas segur@？" onConfirm={() => this.anularDocumento(record.id)} okText="Si" cancelText="No">
                      <a>Anular</a>
                    </Popconfirm>
                    :
                    ''
                  }

                </Space>
              ),
            },
          ]} />
        </div>
      </Content>
    );
  }
}

export default Home;